<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Placement Type</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-data-table
      :headers="headers"
      :items="type_list"
      class="elevation-1"
      :search="search"
    >
      {{ this.type_list }}
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer><span style="width: 20px"> </span>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on"
                >Add Placement Type</v-btn
              >
            </template>
            <v-card>
              <v-form v-model="valid">
                <v-card-title>
                  <v-row justify="space-between">
                    <span class="headline">{{ formTitle }}</span>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                  <!-- <span class="headline">{{ formTitle }}</span> -->
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.type"
                          persistent-hint
                          :hint="
                            editedIndex == -1
                              ? 'For example : Job,Internship,Job And Internship.....'
                              : ''
                          "
                          label="Enter Placement Type"
                          :rules="[(v) => !!v || 'required']"
                        >
                        </v-text-field>
                      </v-col>

                      <!--v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                <v-autocomplete :items="type_master_list" v-model="addedItem.type_master_list" label="Select Placement Type" :rules="[v => !!v || 'required']" persistent-hint
                                                        :hint="editedIndex==-1?'For example :: Job,Internship,Job And Internship....':'For example :: Job,Internship,Job And Internship....'"></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" v-if='editedIndex != -1'>Placement Type
                                                <v-select dense v-model="editedItem.type" :items="type_master_list" item-value="id" persistent-hint  :error="errorMsg.type?true:false" :error-messages="errorMsg.type?'Required':''" persistent-hint
                                                        :hint="editedIndex!=-1?'For example :: Job,Internship,Job And Internship....':'For example :: Job,Internship,Job And Internship....'"></v-select>
                                            </v-col-->
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          dense
                          v-model="editedItem.isactive"
                          color="success"
                          label="Active"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          dense
                          v-model="editedItem.isInternship"
                          color="success"
                          label="Is Internship?"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary darken-1"
                    dark
                    @click="save"
                    :disabled="!valid"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.isinternship="{ item }">
        <v-switch
          v-model="item.isInternship"
          @change="isinternshipswitch(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"
        ></v-switch>
      </template>
      <!-- <template v-slot:item.isactive="{ item }">
            <span v-if="item.isactive">
              <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
            </span>
            <span v-else>
              <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
            </span>
          </template> -->
      <template v-slot:item.isactive="{ item }">
        <v-switch
          v-model="item.isactive"
          @change="activeswitch(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"
        ></v-switch>
      </template>
      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)">
              edit
            </v-icon>
          </v-avatar>
          <v-btn text>
            <v-icon color="red" @click="deletefun(item.id)">
              mdi-delete</v-icon
            ></v-btn
          >
        </div>
      </template>
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    valid: false,
    overlay: false,
    errorMsg: {
      type: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    type_list: null,
    headers: [
      {
        text: "Placement Type",
        align: "left",
        sortable: false,
        value: "type",
      },
      {
        text: "Is Internship",
        align: "left",
        sortable: true,
        value: "isinternship",
      },
      {
        text: "Active",
        align: "left",
        sortable: true,
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    type_list: [],
    editedIndex: -1,
    // addedItem: {
    //     type_master_list: ''
    // },
    editedItem: {
      id: "",
      type: "",
      isactive: true,
      isInternship: false,
      isinternship: true,
    },
    defaultItem: {
      id: "",
      type: "",
      isactive: true,
      isInternship: false,
      isinternship: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Placement Type"
        : "Edit Placement Type";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = true;
      };
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    initializeErrorMsgFalse() {
      this.errorMsg.type = false;
    },
    onLoad() {
      this.overlay = true;
      axios

        .post("/AdminMaster/addPlacementType")

        .then((res) => {
          if (res.data.msg == "200") {
            //window.alert(res.data.msg)

            if ((this.load = true)) {
              this.overlay = false;
              this.type_list = res.data.type_list;
              //   this.type_master_list = res.data.type_master_list
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //   window.console.log(error);
        })
        .finally(() => {
          "use strict";
          var overlay = false;
        });
    },
    editItem(item) {
      this.editedIndex = this.type_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      this.initializeErrorMsgFalse();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        if (this.isValidated()) {
          //  alert(`EditedData:- ${JSON.stringify(this.editedItem)}`)
          axios
            .post("/AdminMaster/editPlacementType", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                //    window.console.log(res.data.msg)
                Object.assign(
                  this.type_list[this.editedIndex],
                  this.editedItem
                );

                this.showSnackbar("#4caf50", res.data.status.message); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                //window.alert(res.data.status.code)
                this.showSnackbar("#b71c1c", res.data.status.message); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      } else {
        //this.address_type_list.push(this.editedItem)
        axios
          .post("/AdminMaster/savePlacementType", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              //window.alert(res.data.status.code)
              this.type_list.push(this.editedItem);
              this.showSnackbar(
                "#4caf50",
                "Placement Type Added Successfully!!!"
              ); // show snackbar on success
              this.onLoad();
            } else if (res.data.status.code == "NA") {
              //window.alert(res.data.status.code)
              this.showSnackbar("#b71c1c", "Placement Type Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    }, //save()
    isValidated() {
      if (this.editedItem.type) {
        return true;
      } else {
        if (!this.editedItem.type) {
          this.errorMsg.type = true;
        }

        return false;
      }
    }, // .....end of isValidated()
    deletefun(item) {
      //console.log("a");
      const data = {
        deleteitem: item,
      };
      //console.log("a1");
      axios
        .post("/AdminMaster/deletePlacementType", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //    console.log("success");
            this.showSnackbar("#4caf50", "Deleted Successfully...");
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    activeswitch(item) {
      //  console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      // console.log(item.id);

      axios
        .post("/AdminMaster/activePlacementType", data)
        .then((res) => {
          //  console.log("res.data");
          //  console.log(res.data);
          if (res.data.msg == "200") {
            //    console.log("res.data");
            //    console.log(res.data);
            //    console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            //    console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },

    isinternshipswitch(item) {
      // console.log("abc");

      const data = {
        itemid: item.id,
      };
      // console.log(item.id);

      axios
        .post("/AdminMaster/isinternshipePlacementType", data)
        .then((res) => {
          //console.log("res.data");
          // console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
